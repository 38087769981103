<template>
  <div v-if="dataResponse !=null ">
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-1">
        <b-row class="align-items-start justify-content-between">
          <b-col cols="6">
            <b><span style="font-size:16px;">Order ID #{{ dataResponse['codeBookingId'] }}</span></b>
          </b-col>
        </b-row>
        <hr>
        <b-row class="align-items-start">
          <b-col
            cols="6"
            class="text-grey"
          >
            <span style="font-size:14px;">Queue ID</span>
          </b-col>
        </b-row>
        <b-row class="align-items-start justify-content-start ml-2">
          <b><span style="font-size:24px;">{{ dataResponse['id'] }}</span></b>
        </b-row>
        <hr>
        <b-row class="align-items-start ml-1 mr-1">
          <span style="font-size:14px;">{{ $t('Services') }} : </span>
          <b><span style="font-size:14px;">{{ showFromCurrentLanguage(dataResponse['queueServiceId']['name']) }}</span></b>
        </b-row>
        <b-row class="align-items-start ml-1">
          <b-col
            v-for="(item,index) in dataResponse['optionServices']"
            :key="index"
            cols="12"
            class="text-grey"
          >
            <span style="font-size:14px;"> - {{ showFromCurrentLanguage(item['option']['name']) }} :</span>
            <b-row
              v-for="(item2,index2) in item['attribute']"
              :key="index2"
              class="text-grey ml-2"
            >
              <span style="font-size:14px;"> - {{ showFromCurrentLanguage(item2['name']) }} ( {{ formatCurrencyWithTHB(item2['price']) }} )</span>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="justify-content-between ml-1 mr-1 mt-2 mb-0">
          <span>{{ $t('Date') }}</span>
          <span>{{ $t('Time') }}</span>
        </b-row>
        <b-row class="justify-content-between ml-1 mr-1 mt-0">
          <b><span>{{ dataResponse['bookingDate']['start'] | formatDate }}</span></b>
          <b><span> {{ dataResponse['bookingDate']['start'] | formatTime }} - {{ dataResponse['bookingDate']['end'] | formatTime }}</span></b>
        </b-row>
        <b-row class="justify-content-between ml-1 mr-1 mt-2 mb-0">
          <span>{{ $t('Member') }}</span>
          <span>{{ $t('Booking ID') }}</span>
        </b-row>
        <b-row class="justify-content-between ml-1 mr-1 mt-0">
          <b><span>{{ dataResponse['userInfoId']['firstName'] }} {{ dataResponse['userInfoId']['lastName'] }}</span></b>
          <b><span> #{{ dataResponse['codeBookingId'] }}</span></b>
        </b-row>
        <b-row class="justify-content-between ml-1 mr-1 mt-2 mb-0">
          <b><span>*{{ $t('Note') }}</span></b>
        </b-row>
        <b-row class="justify-content-between ml-1 mr-1 mt-0">
          <span>{{ dataResponse['description'] }}</span>
        </b-row>
        <hr>
        <b-row class="justify-content-between ml-1 mr-1 mt-2 mb-0">
          <span>{{ $t('Payment') }}</span>
          <span>{{ $t('Status') }}</span>
        </b-row>
        <b-row class="justify-content-between ml-1 mr-1 mt-0">
          <b><span>{{ dataResponse['paymentMethod'] }}</span></b>
          <b><span>{{ dataResponse['bookingStatus'] }}</span></b>
        </b-row>
        <hr>
        <b-row class="justify-content-center mb-1">
          <b-avatar
            size="70px"
            :src="require('@/assets/images/portrait/small/avatar-s-20.jpg')"
          />
        </b-row>
        <b-row class="justify-content-center mb-1">
          <b><span style="font-size:20px;">{{ dataResponse['userInfoId']['firstName'] }} {{ dataResponse['userInfoId']['lastName'] }}</span></b>
        </b-row>
        <b-row class="justify-content-between ml-3 mr-3 mt-0">
          <b><span>
            <feather-icon
              icon="MapIcon"
              size="21"
            /> {{ $t('Map') }}</span></b>
          <b><span> <feather-icon
            icon="PhoneIcon"
            size="21"
          /> {{ dataResponse['userInfoId']['tel'] }}</span></b>
        </b-row>
        <hr>
        <b-row class="justify-content-end ml-1 mr-1 mt-0">
          <b v-if="dataResponse['priceManage']"><span style="font-size:18px; color:red;">({{ $t('Manage Price By Admin') }})</span></b>
          <b class="pl-1"><span style="font-size:18px">{{ formatCurrencyWithTHB(dataResponse['priceSum']) }}</span></b>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>

import {
  BRow,
  BCol,
  BCardText,
  BCard,
  BFormCheckbox,
  BButton,
  BAvatar,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BCard,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  props: {
    dataResponse: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatCurrencyWithTHB(number) {
      const options = {
        style: 'decimal', // Use decimal formatting
        minimumFractionDigits: 0, // Minimum number of decimal places
        maximumFractionDigits: 0, // Maximum number of decimal places
      }

      const formattedNumber = number.toLocaleString('th-TH', options)
      const formattedCurrency = `${formattedNumber}` // Add "บาท" to the formatted number
      return formattedCurrency
    },
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
      if (indexLang > -1) {
        return data[indexLang].value
      }
      return ''
    },
    paymentStatusColor(data) {
      if (data === 'paid') {
        return 'primary'
      }
      if (data === 'pending') {
        return 'warning'
      }
      if (data === 'success') {
        return 'success'
      }
      if (data === 'cancel') {
        return 'danger'
      }
      if (data === 'delete') {
        return 'danger'
      }
      if (data === 'error') {
        return 'danger'
      }
      return 'dark'
    },
  },
}
</script>

<style>
.text-grey{
  color: grey;
}
</style>
